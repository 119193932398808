import React, { useState, useEffect, useCallback, useRef } from "react";
import useKeyboardShortcut from "use-keyboard-shortcut";
import { saveDeck } from "../services/Decks";
import { Box, Typography, Button, IconButton, FormGroup, FormControlLabel, TextField, Checkbox, Select, MenuItem, Stack, FormControl } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Menu } from "@mui/material";
import { debounce } from "lodash";

const Card = (props) => {
  const {
    setIsCardOpen,
    cardData,
    deckId,
    setCardData,
    cardsLastRetrievedTimestamp,
    family,
    user,
    partnerSyncMode,
    setPartnerSyncMode,
    partnerIsOnline,
    partnerState,
    getSetDisplayName,
    selCard,
  } = props;
  const [card, setCard] = useState(selCard);
  const [cardOwner, setCardOwner] = useState("");
  const [inputValues, setInputValues] = useState(card);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorElActionMenu, setAnchorElActionMenu] = useState(null);
  const openActionMenu = Boolean(anchorElActionMenu);
  const handleClickActionMenu = (event) => {
    setAnchorElActionMenu(event.currentTarget);
  };
  const handleCloseActionMenu = () => {
    setAnchorElActionMenu(null);
  };

  const handleClose = () => {
    setIsCardOpen(false);
  };

  useKeyboardShortcut(["Meta", "Enter"], (shortcutKeys) => (!card._id ? addNewCard() : updateCard()), {
    overrideSystem: false,
    ignoreInputFields: false,
    repeatOnHold: false,
  });

  useKeyboardShortcut(
    ["ArrowLeft"],
    (shortcutKeys) => (document.activeElement.tagName === "BODY" || document.activeElement.classList.contains("modal")) && prevCard()
  );

  useKeyboardShortcut(
    ["ArrowRight"],
    (shortcutKeys) => (document.activeElement.tagName === "BODY" || document.activeElement.classList.contains("modal")) && nextCard()
  );

  useKeyboardShortcut(["Escape"], (shortcutKeys) => setIsCardOpen(false));

  useEffect(() => {
    setCard(selCard);

    setInputValues((prevCard) => {
      let updatedCardData = { ...selCard };
      Object.keys(selCard).forEach((key) => {
        if (prevCard[key] !== selCard[key]) {
          console.log("Updated inputs on Card View:", selCard[key]);
          updatedCardData[key] = selCard[key];
        }
      });
      return updatedCardData;
    });
  }, [selCard]);

  useEffect(() => {
    const selCard = cardData.find((cardItem) => cardItem._id === card._id);

    if (selCard) {
      setCardOwner(getSetDisplayName(selCard.type));
    }
  }, [cardData, card, getSetDisplayName]);

  const prevCard = () => {
    if (!selCard["_id"]) return;

    const sameTypeCards = cardData.filter((card) => card.type === selCard.type);
    const selCardIndex = sameTypeCards.findIndex((item) => item._id === selCard._id);

    if (selCardIndex < sameTypeCards.length - 1) {
      props.setSelCard(sameTypeCards[selCardIndex + 1]);
    } else {
      props.setSelCard(sameTypeCards[selCardIndex]);
    }

    setAnchorElActionMenu(null);
  };

  const nextCard = () => {
    const nextCardId = getNextCardId();
    const nextCard = cardData.find((card) => card._id === nextCardId);
    console.log("nextCard", nextCard.name);
    props.setSelCard(nextCard);
    setAnchorElActionMenu(null);
  };

  const getNextCardId = () => {
    if (!selCard["_id"]) return;

    const sameTypeCards = cardData.filter((card) => card.type === selCard.type);
    const selCardIndex = sameTypeCards.findIndex((item) => item._id === selCard._id);

    if (selCardIndex < sameTypeCards.length - 1) {
      return sameTypeCards[selCardIndex + 1]._id;
    } else {
      return sameTypeCards[selCardIndex]._id;
    }
  };

  const handleDeal = (who) => {
    const nextCardId = getNextCardId();
    const nextCard = cardData.find((e) => e["_id"] === nextCardId);

    var updatedCards = [...cardData];
    // Find the card to be updated
    const cardIndex = updatedCards.findIndex((e) => e["_id"] === card._id);
    const updatedCard = updatedCards[cardIndex];

    // Update the card's type and deckId
    updatedCard.type = who;
    updatedCard.deckId = who + "-" + card._id;

    // Remove the card from its current position
    updatedCards.splice(cardIndex, 1);

    // Add the card to the beginning of the array
    updatedCards.unshift(updatedCard);

    setCardData(updatedCards);
    setAnchorElActionMenu(null);
    props.setSelCard(nextCard);
  };

  //   const handleChange = (e) => {
  //     const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
  //     setInputValues({ ...inputValues, [e.target.name]: value }); // Update the input value

  //     const timer = setTimeout(() => {
  //       if (card._id) {
  //         setCardData((prevState) => {
  //           let updatedCardData = prevState.map((item) => {
  //             if (item._id === card._id) {
  //               return { ...card, [e.target.name]: value };
  //             }
  //             return item;
  //           });
  //           return updatedCardData;
  //         });
  //       }
  //     }, 5000);
  //     return () => clearTimeout(timer);
  //   };

const debouncedUpdate = useRef(debounce((name, value, card, setCardData) => {
  if (card._id) {
    setCardData((prevState) => {
      let updatedCardData = prevState.map((item) => {
        if (item._id === card._id) {
          return { ...card, [name]: value };
        }
        return item;
      });
      return updatedCardData;
    });
  }
}, 500));

const updateCardData = useCallback((name, value) => {
  debouncedUpdate.current(name, value, card, setCardData);
}, [card, setCardData]);

const handleChange = (e) => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setInputValues({ ...inputValues, [e.target.name]: value }); // Update the input value immediately

    updateCardData(e.target.name, value); // Debounced update
};

  const updateCard = () => {
    if (card._id) {
      setCardData((prevState) => {
        let updatedCardData = prevState.map((item) => {
          if (item._id === card._id) {
            return card;
          }
          return item;
        });
        return updatedCardData;
      });
      props.setSelCard({});
      // setInputValues({});
      props.setIsCardOpen(false);
    }
  };
  async function addNewCard() {
    let currentCardIds = [];

    cardData.forEach((card) => {
      currentCardIds.push(card._id);
    });
    localStorage.setItem("currCards", JSON.stringify(currentCardIds));

    card.isCustom = true;
    let updatedCards = [{ ...card }, ...cardData];

    saveDeck(props.token, updatedCards, deckId).then((res) => {
      console.log("Saved new card");
      cardsLastRetrievedTimestamp.current = res.timestamp;
      window.location.reload();
    });
  }

  async function duplicateCurrentCard() {
    let currentCardIds = [];

    cardData.forEach((card) => {
      currentCardIds.push(card._id);
    });
    localStorage.setItem("currCards", JSON.stringify(currentCardIds));

    let { _id, ...duplicatedCard } = card;
    duplicatedCard.name = duplicatedCard.name + " (copy)";
    duplicatedCard.isCustom = true;

    const updatedCards = [{ ...duplicatedCard }, ...cardData];

    saveDeck(props.token, updatedCards, deckId).then((res) => {
      console.log("Duplicated card");
      cardsLastRetrievedTimestamp.current = res.timestamp;
      window.location.reload();
    });
  }
  const deleteCustomCard = () => {
    const updatedCards = cardData.filter((item) => item._id !== card._id);
    setCardData(updatedCards);
    props.setSelCard({});
    // setInputValues({});
    setAnchorElActionMenu(null);
    props.setIsCardOpen(false);
  };

  return (
    <>
      <Dialog open={props.isCardOpen} onClose={handleClose} fullScreen={fullScreen}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Box width="80%">
              <TextField
                InputProps={{ style: { fontSize: 30, fontWeight: 600 } }}
                multiline
                fullWidth
                variant="standard"
                name="name"
                value={inputValues.name}
                onChange={handleChange}
              />
            </Box>
            <Box width="20%">
              <IconButton
                id="action-button"
                color="primary"
                size="small"
                aria-controls={openActionMenu ? "action-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openActionMenu ? "true" : undefined}
                onClick={handleClickActionMenu}
              >
                <EditIcon />
                Actions
              </IconButton>
              <Menu
                id="action-menu"
                anchorEl={anchorElActionMenu}
                open={openActionMenu}
                onClose={handleCloseActionMenu}
                MenuListProps={{
                  "aria-labelledby": "action-button",
                }}
              >
                <MenuItem
                  onClick={(e) => {
                    handleDeal("me");
                  }}
                >
                  Deal to {props.leftUserName}
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    handleDeal("them");
                  }}
                >
                  Deal to {props.rightUserName}
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    duplicateCurrentCard();
                  }}
                >
                  Split (Duplicate)
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    handleDeal("unassigned");
                  }}
                >
                  Unassign
                </MenuItem>
                <MenuItem
                  value="Suppress"
                  onClick={(e) => {
                    handleDeal("suppressed");
                  }}
                >
                  Suppress
                </MenuItem>
                <MenuItem
                  color="error"
                  onClick={(e) => {
                    deleteCustomCard();
                  }}
                  disabled={card.isCustom}
                >
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Typography variant="caption">{cardOwner}</Typography>
          </Box>

          <form>
            <Box>
              <Stack mb={2} spacing={2} direction="row">
                <FormControl>
                  {/* <InputLabel id="label-suit">Suit</InputLabel> */}
                  <Select size="small" name="suit" id="suit" labelId="label-suit" value={inputValues.suit} onChange={handleChange}>
                    <MenuItem value="none">None</MenuItem>
                    <MenuItem value="home">Home</MenuItem>
                    <MenuItem value="out">Out</MenuItem>
                    <MenuItem value="caregiving">Caregiving</MenuItem>
                    <MenuItem value="magic">Magic</MenuItem>
                    <MenuItem value="unicornspace">Unicorn Space</MenuItem>
                    <MenuItem value="wild">Wild</MenuItem>
                  </Select>
                </FormControl>
                <FormGroup>
                  <FormControlLabel control={<Checkbox name="grind" id="grind" checked={inputValues.grind || false} onChange={handleChange} />} label="Daily Grind" />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel control={<Checkbox name="requiresAction" id="requiresAction" checked={inputValues.requiresAction || false} onChange={handleChange} />} label="Requires Action" />
                </FormGroup>
              </Stack>
              <Stack spacing={2}>
                <TextField
                  variant="standard"
                  multiline
                  fullWidth
                  label="Definition"
                  name="definition"
                  value={inputValues.definition}
                  placeholder="Enter a definition"
                  onChange={handleChange}
                />
                <TextField
                  variant="standard"
                  multiline
                  fullWidth
                  label="Conception"
                  name="conception"
                  value={inputValues.conception}
                  placeholder="Enter a steps for conceiving"
                  onChange={handleChange}
                />
                <TextField
                  variant="standard"
                  multiline
                  fullWidth
                  label="Planning"
                  name="planning"
                  value={inputValues.planning}
                  placeholder="Enter steps for planning"
                  onChange={handleChange}
                />
                <TextField
                  variant="standard"
                  multiline
                  fullWidth
                  label="Execution"
                  name="execution"
                  value={inputValues.execution}
                  placeholder="Enter steps for execution"
                  onChange={handleChange}
                />
                <TextField
                  variant="standard"
                  label="Minimum Standard of Care'"
                  multiline
                  fullWidth
                  name="msc"
                  value={inputValues.msc}
                  placeholder="Enter mutually agreed minimmum standard for conceiving, planning & execution of task"
                  onChange={handleChange}
                />
              </Stack>
            </Box>
          </form>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          {card._id && (
            <>
              {!partnerSyncMode && (
                <Box mt={2}>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={(e) => {
                      prevCard();
                    }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={(e) => {
                      nextCard();
                    }}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </Box>
              )}
              {partnerIsOnline && !partnerState.partnerSyncMode && (
                <Box>
                  <Typography mr={1} component="span" variant="body2">
                    {family.me._id === user._id ? family.them.firstName : family.me.firstName} is online
                  </Typography>
                  {partnerSyncMode === false && (
                    <Button
                      size="small"
                      color="success"
                      variant="outlined"
                      onClick={(e) => {
                        setPartnerSyncMode(true);
                      }}
                    >
                      Follow
                    </Button>
                  )}
                  {partnerSyncMode === true && (
                    <Button
                      color="warning"
                      size="small"
                      variant="outlined"
                      onClick={(e) => {
                        setPartnerSyncMode(false);
                      }}
                    >
                      Un-follow
                    </Button>
                  )}
                </Box>
              )}
              {partnerIsOnline && partnerState.partnerSyncMode && (
                <Box>
                  <Typography mr={1} component="span" variant="body2">
                    {family.me._id === user._id ? family.them.firstName : family.me.lastName} is following YOU
                  </Typography>
                </Box>
              )}
              {!partnerSyncMode && (
                <Box mt={2}>
                  <Button
                    value="Close"
                    onClick={() => {
                      props.setSelCard();
                      /* setInputValues(); */ props.setIsCardOpen(false);
                    }}
                  >
                    Close
                  </Button>
                </Box>
              )}
            </>
          )}

          {!card._id && (
            <>
              <Button value="Cancel" onClick={() => props.setIsCardOpen(false)}>
                Cancel
              </Button>
              <Button value="Save" onClick={addNewCard}>
                Save
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Card;
